import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import useStores from '../../../../../hooks/useStores';
import {
  UpdateAllModelStatusesRequest,
  UpdateAllModelStatusesResponse,
  UpdateModelStatusReponse,
  UpdateModelStatusRequest,
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../models/vehicleModel.model';
import { handleErrorResponse } from '../../../../../utils/errorHandlingUtils';
import {
  updateAllModelStatuses,
  updateModelStatuses,
} from '../../../../../webservices/vehicleModelsApi';

const useModelsFieldStatusApi = (
  seriesId: string,
  year: string,
  models: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  const {
    userStore: { brand, langPermissions },
    teamStore: {
      team: { languages: teamLangs, name: teamName },
    },
    limitedDataStatusStore: { setDisabled: setDisabledLimitedDataStatusButton },
    vehicleModelsStore: { setModels },
  } = useStores();

  const updateFieldStatus = async (payload: UpdateModelStatusRequest) => {
    try {
      setDisabledLimitedDataStatusButton(true);
      const promises: Promise<any>[] = [];
      teamLangs
        .filter(lang => !!langPermissions[lang]?.canEdit)
        .forEach(lang => {
          promises.push(
            trackPromise(updateModelStatuses(brand, teamName, seriesId, year, lang, payload))
          );
        });

      const response = await Promise.all(promises);
      const data: UpdateModelStatusReponse = response[0].data;
      const thisModel = models.find(item => item.id === data.id);
      thisModel?.updateDynamicProps({ fieldStatus: { ...data } });
      setModels([...models]);
      toast.success(`Model Field Status - updated successfully`);
      setDisabledLimitedDataStatusButton(false);
    } catch (e) {
      setDisabledLimitedDataStatusButton(false);
      handleErrorResponse(e, 'Error in updating model field status');
    }
  };

  const updateAllFieldStatuses = async (payload: UpdateAllModelStatusesRequest) => {
    try {
      const promises: Promise<any>[] = [];
      teamLangs
        .filter(lang => !!langPermissions[lang]?.canEdit)
        .forEach(lang => {
          promises.push(
            trackPromise(updateAllModelStatuses(brand, teamName, seriesId, year, lang, payload))
          );
        });

      const response = await Promise.all(promises);
      const data: UpdateAllModelStatusesResponse = response[0].data;
      models.forEach(item => {
        item.updateDynamicProps({ fieldStatus: data[item.id] });
      });
      setModels([...models]);
      toast.success(`All Model Field Statuses - updated successfully`);
    } catch (e) {
      handleErrorResponse(e, 'Error in updating model field status');
    }
  };

  return { updateFieldStatus, updateAllFieldStatuses };
};

export default useModelsFieldStatusApi;
