import { AxiosResponse } from 'axios';
import { ChangeLogTypes } from '../models/changeLog.model';
import { KeyValueType } from '../models/common.model';
import { FuelTypeItemResponse } from '../models/fuelType.model';
import {
  SeriesSettingsChangeTypeMap,
  SeriesSettingsItem,
  SeriesSettingsResponse,
  SeriesSettingsReviewMap,
  SeriesSettingsReviewResponse,
  SeriesSettingsReviewType,
  SubSeriesSettingsReviewResponse,
} from '../models/seriesSettings.model';
import { Brand } from '../models/user.model';
import { sortBy } from '../utils';

export const seriesSettingsXForm = (
  seriesSettingsResponse: AxiosResponse<SeriesSettingsResponse[]>,
  brand: Brand,
  excludeDeletedSubSeries = false
) => {
  let counter = 0;
  const seriesSettings = seriesSettingsResponse.data.map(
    item => new SeriesSettingsItem(brand, item)
  );
  seriesSettings.forEach(setting => {
    counter = counter + 1;
    setting.sortOrder = counter;
    if (Object.keys(setting.subSeries).length) {
      Object.entries(setting.subSeries).forEach(item => {
        counter = counter + 1;
        const subSeries = new SeriesSettingsItem(brand);
        subSeries.sortOrder = counter;
        subSeries.isSubSeries = true;
        const seriesSetting = xformSubSeries(item[1], subSeries, setting.revId);
        if (!item[1].isDeleted || !excludeDeletedSubSeries) {
          seriesSettings.push(seriesSetting);
        }
      });
    }
  });
  const sortedSeriesSettings = seriesSettings.slice().sort(sortBy('sortOrder', false));

  return { sortedSeriesSettings };
};

const xformSubSeries = (respObj: SeriesSettingsResponse, subSeries: any, parentRevId: string) => {
  Object.entries(respObj).forEach(([respKey, respValue]) => {
    subSeries[respKey] = respValue;
    subSeries.parentRevId = parentRevId;
  });
  return subSeries;
};

export const getSeriesNameByIdUtil = (seriesSettings: SeriesSettingsItem[], id: string) => {
  const item = seriesSettings.find(settings => settings.id === id);
  return !item ? id : item.name;
};

export const getSeriesIdByNameUtil = (seriesSettings: SeriesSettingsItem[], name: string) => {
  const item = seriesSettings.find(settings => settings.name === name);
  return !item ? name : item.id;
};

export const getFuelTypeNameByIdUtil = (fuelTypesList: FuelTypeItemResponse[], id: string) => {
  const item = fuelTypesList.find(type => type.id === id);
  return !item ? id : item.type;
};

export const getFuelTypeIdByNameUtil = (fuelTypesList: FuelTypeItemResponse[], name: string) => {
  const item = fuelTypesList.find(type => type.type === name);
  return !item ? name : item.id;
};

export const seriesSettingsReviewXForm = (
  items: SeriesSettingsReviewResponse[],
  fuelTypesMap: KeyValueType<string>,
  seriesCategoriesMap: KeyValueType<string>
) => {
  const map: SeriesSettingsReviewMap = {};

  items.forEach(item => {
    if (Object.keys(item.changes).length) {
      if (!map[item.id]) {
        map[item.id] = getDefaultChangeTypeMap(item, fuelTypesMap, seriesCategoriesMap);
      }
      updateMap(map, item, fuelTypesMap, seriesCategoriesMap);
    }
    if (item.subSeries) {
      Object.values(item.subSeries).forEach(subSeriesItem => {
        if (Object.keys(subSeriesItem.changes).length) {
          if (!map[subSeriesItem.id]) {
            map[subSeriesItem.id] = getDefaultChangeTypeMap(
              subSeriesItem,
              fuelTypesMap,
              seriesCategoriesMap
            );
          }
          updateMap(map, subSeriesItem, fuelTypesMap, seriesCategoriesMap);
          map[subSeriesItem.id].parentId = subSeriesItem.parentId;
        }
      });
    }
  });

  return map;
};

export const getNameMap = (idToNameMap: KeyValueType<string>, idMap: KeyValueType<boolean>) => {
  const nameMap: KeyValueType<boolean> = {};
  Object.keys(idMap).forEach(id => {
    if (idMap[id]) {
      const name = idToNameMap[id];
      if (name) {
        nameMap[name] = true;
      }
    }
  });
  return nameMap;
};

const getKeyFromChangeLogType = (type: ChangeLogTypes): SeriesSettingsReviewType | undefined => {
  switch (type) {
    case ChangeLogTypes.NAME:
      return 'name';
    case ChangeLogTypes.FUEL_TYPES:
      return 'fuelTypes';
    case ChangeLogTypes.SERIES_CATEGORIES:
      return 'seriesCategories';
    case ChangeLogTypes.SEATING:
      return 'seating';
    case ChangeLogTypes.ESTIMATED_MILEAGE:
      return 'estimatedMileage';
    case ChangeLogTypes.MPGE:
      return 'mpge';
    case ChangeLogTypes.RANGE:
      return 'range';
    case ChangeLogTypes.SERIES_ADDED:
      return 'added';
    case ChangeLogTypes.SERIES_DELETED:
      return 'deleted';
  }
  return undefined;
};

const getDefaultChangeTypeMap = (
  item: SeriesSettingsReviewResponse | SubSeriesSettingsReviewResponse,
  fuelTypesMap: KeyValueType<string>,
  seriesCategoriesMap: KeyValueType<string>
): SeriesSettingsChangeTypeMap => {
  const estimatedMileage = item.estimatedMileage || '';
  const mpge = item.mpge || '';
  const range = item.range || '';
  return {
    id: item.id,
    revId: item.revId,
    isApplied: false,
    isNew: false,
    isDeleted: false,
    rejectNotes: '',
    notes: '',
    isInProgress: false,
    isPublishable: item.isPublishable,
    name: {
      before: '',
      after: item.name,
      hasChanged: false,
    },
    fuelTypes: {
      before: getNameMap(fuelTypesMap, item.fuelTypes),
      after: getNameMap(fuelTypesMap, item.fuelTypes),
      hasChanged: false,
    },
    seriesCategories: {
      before: {},
      after: getNameMap(seriesCategoriesMap, item.seriesCategories),
      hasChanged: false,
    },
    seating: {
      before: '',
      after: item.seating,
      hasChanged: false,
    },
    estimatedMileage: {
      before: estimatedMileage,
      after: estimatedMileage,
      hasChanged: false,
    },
    mpge: {
      before: mpge,
      after: mpge,
      hasChanged: false,
    },
    range: {
      before: range,
      after: range,
      hasChanged: false,
    },
  } as SeriesSettingsChangeTypeMap;
};

const updateMap = (
  map: SeriesSettingsReviewMap,
  item: SeriesSettingsReviewResponse | SubSeriesSettingsReviewResponse,
  fuelTypeMap: KeyValueType<string>,
  seriesCategoriesMap: KeyValueType<string>
) => {
  let isApplied = true;
  let rejectNotes = '';
  Object.entries(item.changes).forEach(([key, change]) => {
    isApplied = isApplied && change.isApplied;
    rejectNotes = change.rejectNotes || rejectNotes;
    const changeLogKey = getKeyFromChangeLogType(change.changeType);
    if (changeLogKey === 'deleted') {
      map[item.id].isDeleted = true;
      map[item.id].name.before = map[item.id].name.after;
      map[item.id].name.after = '';
      map[item.id].name.hasChanged = true;

      map[item.id].fuelTypes.before = map[item.id].fuelTypes.after;
      map[item.id].fuelTypes.after = {};
      map[item.id].fuelTypes.hasChanged = true;

      map[item.id].seriesCategories.before = map[item.id].seriesCategories.after;
      map[item.id].seriesCategories.after = {};
      map[item.id].seriesCategories.hasChanged = true;

      map[item.id].seating.before = map[item.id].seating.after;
      map[item.id].seating.after = '';
      map[item.id].seating.hasChanged = true;

      map[item.id].estimatedMileage.before = map[item.id].estimatedMileage.after;
      map[item.id].estimatedMileage.after = '';
      map[item.id].estimatedMileage.hasChanged = true;

      map[item.id].mpge.before = map[item.id].mpge.after;
      map[item.id].mpge.after = '';
      map[item.id].mpge.hasChanged = true;

      map[item.id].range.before = map[item.id].range.after;
      map[item.id].range.after = '';
      map[item.id].range.hasChanged = true;
    } else if (changeLogKey === 'added') {
      map[item.id].isNew = true;
      map[item.id].name.hasChanged = true;

      map[item.id].fuelTypes.before = {}; // have to set this to empty b/c fuelTypes.before is initialized (rather than set to empty) at the start
      map[item.id].fuelTypes.hasChanged = true;

      map[item.id].seriesCategories.hasChanged = true;
      map[item.id].seating.hasChanged = true;

      map[item.id].estimatedMileage.before = '';
      map[item.id].estimatedMileage.hasChanged = true;

      map[item.id].mpge.before = '';
      map[item.id].mpge.hasChanged = true;

      map[item.id].range.before = '';
      map[item.id].range.hasChanged = true;
    } else if (changeLogKey) {
      if (changeLogKey === 'fuelTypes') {
        const beforeMap = (change.before as any) as KeyValueType<boolean>;
        const beforeFuelTypes = getNameMap(fuelTypeMap, beforeMap);
        map[item.id].fuelTypes.before = beforeFuelTypes;

        const afterMap = (change.after as any) as KeyValueType<boolean>;
        const afterFuelTypes = getNameMap(fuelTypeMap, afterMap);
        map[item.id].fuelTypes.after = afterFuelTypes;
      } else if (changeLogKey === 'seriesCategories') {
        const beforeMap = (change.before as any) as KeyValueType<boolean>;
        const beforeSeriesCategories = getNameMap(seriesCategoriesMap, beforeMap);
        map[item.id].seriesCategories.before = beforeSeriesCategories;

        const afterMap = (change.after as any) as KeyValueType<boolean>;
        const afterSeriesCategories = getNameMap(seriesCategoriesMap, afterMap);
        map[item.id].seriesCategories.after = afterSeriesCategories;
      } else {
        map[item.id][changeLogKey].before = change.before as string;
        map[item.id][changeLogKey].after = change.after as string;
      }
      map[item.id][changeLogKey].hasChanged = true;
    }
  });
  const fuelTypes = map[item.id].fuelTypes;
  let checkEstimatedMileage = updateMileage(map, item.id, 'before', fuelTypes.before);
  checkEstimatedMileage =
    updateMileage(map, item.id, 'after', fuelTypes.after) || checkEstimatedMileage;

  // if true (i.e. fuelTypes.before or fuelTypes.after are only battery electric) then make the estimated mileage comparison again
  if (checkEstimatedMileage) {
    map[item.id].estimatedMileage.hasChanged =
      map[item.id].estimatedMileage.hasChanged ||
      map[item.id].estimatedMileage.before !== map[item.id].estimatedMileage.after;
  }
  if (fuelTypes.before['Battery Electric'] || fuelTypes.after['Battery Electric']) {
    map[item.id].range.hasChanged =
      map[item.id].range.hasChanged || map[item.id].range.before !== map[item.id].range.after;
  }
  if (
    fuelTypes.before['Fuel Cell'] ||
    fuelTypes.after['Fuel Cell'] ||
    fuelTypes.before['Plug-in Hybrid'] ||
    fuelTypes.after['Plug-in Hybrid']
  ) {
    map[item.id].mpge.hasChanged =
      map[item.id].mpge.hasChanged || map[item.id].mpge.before !== map[item.id].mpge.after;
  }
  if (!map[item.id].estimatedMileage.hasChanged) {
    map[item.id].estimatedMileage.before = '';
  }
  if (!map[item.id].range.hasChanged) {
    map[item.id].range.before = '';
  }
  if (!map[item.id].mpge.hasChanged) {
    map[item.id].mpge.before = '';
  }

  map[item.id].isApplied = isApplied;
  map[item.id].rejectNotes = rejectNotes;
};

const updateMileage = (
  map: SeriesSettingsReviewMap,
  itemId: string,
  type: 'before' | 'after',
  fuels: KeyValueType<boolean>
) => {
  let checkEstimatedMileage = false;
  // range is only applicable to battery electric fuel types
  if (!fuels['Battery Electric']) {
    map[itemId].range[type] = '';
  }
  // mpge is only applicable to fuel cell and plug in hybrid fuel types
  if (!fuels['Fuel Cell'] && !fuels['Plug-in Hybrid']) {
    map[itemId].mpge[type] = '';
    // estimatedMileage is applicable to every fuel type except battery electric
    if (!fuels['Gas'] && !fuels['Hybrid']) {
      map[itemId].estimatedMileage[type] = '';
      checkEstimatedMileage = true;
    }
  }
  return checkEstimatedMileage;
};
