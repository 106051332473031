import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import useStores from '../../../../../hooks/useStores';
import { Brand } from '../../../../../models/user.model';
import { VehicleDataVersionInfo } from '../../../../../models/vehicleData.model';
import { transformResponseModels } from '../../../../../utils/modelsUtils';
import { getFuelTypes } from '../../../../../webservices/adminApi';
import { getTeamVersions } from '../../../../../webservices/vehicleAdminApi';
import { getGrades, getModels } from '../../../../../webservices/vehicleModelsApi';
import { toGqlBrand } from '../../../../../utils/graphqlUtils';

interface IUseVersionsProps {
  setReadOnly: (readOnly: boolean) => void;
}

const useVersions = ({ setReadOnly }: IUseVersionsProps) => {
  const { userStore, teamStore, vehicleModelsStore } = useStores();

  const [isVersionLoaded, setIsVersionLoaded] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);
  const [currentLog, setCurrentLog] = useState('');
  const [currentVersionInfo, setCurrentVersionInfo] = useState<VehicleDataVersionInfo>(
    {} as VehicleDataVersionInfo
  );

  const { param: teamParam, defaultLanguage: defaultLang } = teamStore.team;
  const { brand } = userStore;
  const { seriesId, year } = useParams<{
    seriesId: string;
    year: string;
  }>();

  const onVersionChange = useCallback(
    async (selectedVersion: string) => {
      const [modelsResponse, gradesResponse, fuelTypesResponse] = await Promise.all([
        getModels({
          brand,
          team: teamParam,
          series: seriesId,
          year,
          version: selectedVersion,
          includeAll: true,
          language: defaultLang,
        }),
        getGrades({
          brand,
          team: teamParam,
          series: seriesId,
          year,
          version: selectedVersion,
          includeAll: true,
          language: defaultLang,
        }),
        getFuelTypes({ brand: toGqlBrand(brand) }),
      ]); // fetch the delete records as well);

      const { fuelTypes, grades, vehicleModels } = transformResponseModels(
        brand as Brand,
        modelsResponse,
        gradesResponse,
        fuelTypesResponse,
        vehicleModelsStore.getLocalStorage(teamParam)
      );

      vehicleModelsStore.fuelTypes = fuelTypes;
      vehicleModelsStore.grades = grades;
      vehicleModelsStore.vehicleModels = vehicleModels;

      setCurrentVersionInfo({ [defaultLang]: selectedVersion });
      setCurrentLog(selectedVersion);
    },
    [
      setCurrentVersionInfo,
      setCurrentLog,
      seriesId,
      year,
      brand,
      teamParam,
      defaultLang,
      vehicleModelsStore,
    ]
  );

  useEffect(() => {
    setIsVersionLoaded(false);

    (async () => {
      if (!teamParam || !defaultLang) return;

      try {
        // fetch the versions for the default language
        const versionsResponse = await getTeamVersions(
          brand,
          teamParam,
          seriesId,
          year,
          defaultLang
        );

        const versions = versionsResponse.data;
        const versionsList: string[] = [];

        versions.forEach(item => {
          versionsList.push(item.version);
        });

        setLogs(versionsList);

        const isSubmitted = versions[0].isSubmitted;
        if (isSubmitted) {
          setReadOnly(isSubmitted);
        }

        await onVersionChange(versionsList[0]);
      } catch (error) {
        toast.error('Error loading versions');
      }

      setIsVersionLoaded(true);
    })();
  }, [
    setReadOnly,
    setIsVersionLoaded,
    setLogs,
    onVersionChange,
    seriesId,
    year,
    brand,
    teamParam,
    defaultLang,
  ]);

  return { logs, isVersionLoaded, currentVersionInfo, currentLog, onVersionChange };
};

export default useVersions;
