import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'vapi-ui-common';
import { VDStatus } from '../../constants/vehicleData/VDConstants';
import { KeyValueType } from '../../models/common.model';
import { BRAND_TDPR, BRAND_TOYOTA, Language } from '../../models/user.model';
import { VehicleTeam } from '../../models/vehicleData.model';
import { getSeries } from '../../webservices/adminApi';
import { getVehicleData } from '../../webservices/vehicleAdminApi';
import styles from './syncUpdatesPopover.module.scss';
import SyncUpdatesPopoverContent from './SyncUpdatesPopoverContent';
import { Brand } from '../../gql/generated';

interface ISyncUpdatesPopover {
  sourceEnVersion: string | undefined;
  brand: string;
  team: VehicleTeam;
  seriesId: string;
  year: string;
  syncUpdates: () => void;
  align?: 'right' | 'left';
}

const getDates = async (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  version: string,
  key: 'lastSyncDate' | 'lastPublishedDate'
) => {
  const dates: KeyValueType<string> = { [Language.EN]: '', [Language.ES]: '' };
  const enResponse = await getVehicleData(brand, team, seriesId, year, version, Language.EN);
  const esResponse = await getVehicleData(brand, team, seriesId, year, version, Language.ES);
  if (enResponse.data[key]) {
    dates[Language.EN] = enResponse.data[key]!;
  }
  if (esResponse.data[key]) {
    dates[Language.ES] = esResponse.data[key]!;
  }
  let hasSourceUpdates =
    !!(enResponse.data.hasSourceUpdates && enResponse.data.hasSourceUpdates.toyota) ||
    !!(esResponse.data.hasSourceUpdates && esResponse.data.hasSourceUpdates.toyota);
  return { dates, hasSourceUpdates };
};

const SyncUpdatesPopover = ({
  sourceEnVersion,
  brand,
  team,
  seriesId,
  year,
  syncUpdates,
  align = 'right',
}: ISyncUpdatesPopover) => {
  const [lastSyncDates, setLastSyncDates] = useState<KeyValueType<string>>({});
  const [lastPublishedDates, setLastPublishedDates] = useState<KeyValueType<string>>({});
  const [currentEnVersion, setCurrentEnVersion] = useState<string | undefined>(sourceEnVersion);
  const [show, setShow] = useState<boolean>(false);
  const [tooltipHover, setTooltipHover] = useState(false);

  useEffect(() => {
    if (lastSyncDates[Language.EN] == null) {
      (async () => {
        if (brand === BRAND_TOYOTA && team === VehicleTeam.AGENCY_TEAM) {
          const esResponse = await getVehicleData(
            brand,
            team,
            seriesId,
            year,
            VDStatus.DRAFT.toUpperCase(),
            Language.ES
          );
          const enResponse = await getVehicleData(
            brand,
            team,
            seriesId,
            year,
            VDStatus.PUBLISHED.toUpperCase(),
            Language.EN
          );

          setLastSyncDates({ ...lastSyncDates, [Language.EN]: esResponse.data.lastSyncDate ?? '' });
          setLastPublishedDates({ [Language.EN]: enResponse.data.lastPublishedDate ?? '' });

          if (enResponse.data.version) {
            setCurrentEnVersion(enResponse.data.version.toString());
          }
        } else if (brand === BRAND_TDPR) {
          const { dates: syncDates, hasSourceUpdates } = await getDates(
            brand,
            team,
            seriesId,
            year,
            VDStatus.DRAFT.toUpperCase(),
            'lastSyncDate'
          );

          const tdprSeries = await getSeries({ brand: Brand.Tdpr });
          let toyotaSeriesId = '';
          for (const series of Object.values(tdprSeries.series)) {
            if (
              series.id === seriesId &&
              series.sourceBrand === BRAND_TOYOTA &&
              series.sourceSeriesId
            ) {
              toyotaSeriesId = series.sourceSeriesId;
              break;
            }
          }
          if (toyotaSeriesId) {
            const { dates: publishedDates } = await getDates(
              BRAND_TOYOTA,
              team,
              toyotaSeriesId,
              year,
              VDStatus.PUBLISHED.toUpperCase(),
              'lastPublishedDate'
            );
            setLastPublishedDates(publishedDates);
          }
          setShow(hasSourceUpdates);
          setLastSyncDates({ ...lastSyncDates, [Language.EN]: syncDates[Language.EN] });
        }
      })();
    }
  }, [brand, team, seriesId, year, lastSyncDates]);

  const onSyncUpdatesHover = (isOn: boolean) => {
    setTooltipHover(isOn);
  };

  if (currentEnVersion === sourceEnVersion && !show) {
    return <></>;
  }

  return (
    <Button
      variant="primary"
      onMouseOver={() => {
        onSyncUpdatesHover(true);
      }}
      onMouseOut={() => {
        onSyncUpdatesHover(false);
      }}
      onClick={syncUpdates}
    >
      Sync Updates
      {tooltipHover && (
        <Popover
          popoverElementClass={styles.popover}
          align={align}
          defaultOpen
          toggleElement={<></>}
          popoverElement={
            <SyncUpdatesPopoverContent
              lastSyncDates={lastSyncDates}
              lastPublishedDates={lastPublishedDates}
            />
          }
        />
      )}
    </Button>
  );
};

export default SyncUpdatesPopover;
