import { action, computed, observable } from 'mobx';
import { KeyValueType } from '../../../models/common.model';
import { SeriesSettingsReviewMap } from '../../../models/seriesSettings.model';
import { Brand } from '../../../models/user.model';
import { VehicleTeam } from '../../../models/vehicleData.model';
import { toLowerCase } from '../../../utils';
import { seriesSettingsReviewXForm } from '../../../utils/seriesSettingsUtils';
import { getFuelTypes, getSeriesCategories } from '../../../webservices/adminApi';
import { getReviewSeriesSettings } from '../../../webservices/vehicleModelsApi';
import BaseReviewStore from './baseReviewStore';
import { toGqlBrand } from '../../../utils/graphqlUtils';

class SeriesSettingsReviewStore extends BaseReviewStore {
  @observable fuelTypesMap: KeyValueType<string> = {};
  @observable codeRedFuelTypes: KeyValueType<true> = {}; // map of fuelType name to true
  @observable map: SeriesSettingsReviewMap = {};
  @observable seriesCategoriesMap: KeyValueType<string> = {};

  @action fetchData = async (
    brand: Brand,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    version?: string,
    getFullData: boolean = false
  ) => {
    const [
      fuelTypesResponse,
      seriesSettingsResponse,
      seriesCategoriesResponse,
    ] = await Promise.all([
      getFuelTypes({ brand: toGqlBrand(brand) }),
      getReviewSeriesSettings(brand, team, seriesId, year, version),
      getFullData
        ? getSeriesCategories({ brand: toGqlBrand(brand), getLatestVersion: true })
        : Promise.resolve(undefined),
    ]);

    this.fuelTypesMap = {};
    fuelTypesResponse.fuelTypes.forEach(fuelType => {
      this.fuelTypesMap[fuelType.id] = fuelType.type;
    });

    this.codeRedFuelTypes = {};
    seriesSettingsResponse.data.forEach(x => {
      if (!x.isPublishable) {
        Object.keys(x.fuelTypes).forEach((fuelTypeId: string) => {
          this.codeRedFuelTypes[this.fuelTypesMap[fuelTypeId]] = true;
        });
      }
    });

    if (getFullData && seriesCategoriesResponse) {
      this.seriesCategoriesMap = {};
      seriesCategoriesResponse.forEach(seriesCategory => {
        this.seriesCategoriesMap[seriesCategory.id] = seriesCategory.name;
      });
      this.map = seriesSettingsReviewXForm(
        seriesSettingsResponse.data,
        this.fuelTypesMap,
        this.seriesCategoriesMap
      );
    }
  };

  @computed get filteredReviewItems() {
    const items = Object.values(this.map).slice();

    const lowerSearchText = toLowerCase(this.searchText);
    return items.filter(seriesSettings => {
      if (lowerSearchText) {
        const valuesToCheck: string[] = [seriesSettings.name.before, seriesSettings.name.after];
        valuesToCheck.push(...Object.keys(seriesSettings.fuelTypes.before));
        valuesToCheck.push(...Object.keys(seriesSettings.fuelTypes.after));
        valuesToCheck.push(...Object.keys(seriesSettings.seriesCategories.before));
        valuesToCheck.push(...Object.keys(seriesSettings.seriesCategories.after));
        console.log(valuesToCheck);
        for (let val of valuesToCheck) {
          if (toLowerCase(val).includes(lowerSearchText)) {
            return true;
          }
        }
      }

      return !lowerSearchText;
    });
  }

  @action reset = () => {
    this.searchText = '';
    this.fuelTypesMap = {};
    this.codeRedFuelTypes = {};
    this.map = {};
    this.seriesCategoriesMap = {};
  };
}

export default SeriesSettingsReviewStore;
