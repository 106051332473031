import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, DropdownList } from 'vapi-ui-common';
import Checkbox from '../../../../../../../components/Checkbox';
import IconTextButton from '../../../../../../../components/IconTextButton';
import Input from '../../../../../../../components/Input';
import { TableRow } from '../../../../../../../components/Table';
import { IDValueType, KeyValueType } from '../../../../../../../models/common.model';
import { RefItem } from '../../../../../../../models/refItem.model';
import { SeriesSettingsItem } from '../../../../../../../models/seriesSettings.model';
import { Language } from '../../../../../../../models/user.model';
import { SeriesCategories } from '../../../../../../../models/vehicleData.model';
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from '../../../../../../../models/vehicleModel.model';
import styles from '../../../modelsModal.module.scss';
import HiddenField from './HiddenField';
import Label from './Label';

interface ModelsEditPanelProps {
  model: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>;
  category: SeriesCategories;
  seriesName: string;
  fuelTypes: IDValueType[];
  onClose: () => void;
  onAddGrade?: (grade: string) => void;
  onUpdateGrade?: (gradeId: string, gradeValue: string) => void;
  onSave?: (
    modelLangMap: KeyValueType<VehicleModelItem<VehicleModelLexus | VehicleModelToyota>>
  ) => void;
  seriesSettings: SeriesSettingsItem[];
  rowClass?: string;
  readOnly?: boolean;
}

const ModelsEditPanelLexus = ({
  category,
  seriesName,
  fuelTypes,
  model,
  onClose,
  seriesSettings,
  onSave = () => {},
  rowClass,
  readOnly = false,
}: ModelsEditPanelProps) => {
  const cloneModelProps = Object.assign({}, model.getModelPropValues());

  const [modelVm, setModelVm] = useState(cloneModelProps);
  const [possibleFuelTypes, setPossibleFuelTypes] = useState<string[]>([]);

  useEffect(() => {
    const _possibleFuelTypes: string[] = [];
    const f: Set<string> = new Set();
    seriesSettings.forEach(element => {
      if (element.id === modelVm.seriesSettingId) {
        Object.keys(element.fuelTypes).forEach(key => {
          if (element.fuelTypes[key]) {
            f.add(key);
          }
        });
      }
    });
    fuelTypes.forEach(fuel => {
      if (f.has(fuel.id)) {
        _possibleFuelTypes.push(fuel.value);
      }
    });
    setPossibleFuelTypes(_possibleFuelTypes);
  }, [seriesSettings, fuelTypes, modelVm.seriesSettingId]);

  useEffect(() => {
    const defaultFuelType = possibleFuelTypes.length > 0 ? possibleFuelTypes[0] : '';
    const currentFuel = possibleFuelTypes.includes(modelVm.fuelType?.value)
      ? modelVm.fuelType?.value
      : defaultFuelType;
    if (currentFuel && (!modelVm.fuelType || currentFuel !== modelVm.fuelType?.value)) {
      setModelVm({
        ...modelVm,
        fuelType: fuelTypes.find(item => item.value === currentFuel),
      });
    }
  }, [possibleFuelTypes, modelVm, fuelTypes]);

  const handleOnSave = () => {
    if (isValid()) {
      model.updateDynamicProps(modelVm);
      model.trimValues();
      onSave({
        [Language.EN]: model,
      });
      onClose();
    } else {
      toast.error('Please fill in the required fields');
    }
  };

  const isValid = () => {
    let isValid = true;
    model.iterateModelProps((modelPropKey, modelProp, modelPropIndex) => {
      if (!isValid || modelProp.optional) {
        return;
      }
      const thisRequiredField = modelVm[modelPropKey];
      if (
        thisRequiredField === undefined ||
        thisRequiredField === '' ||
        thisRequiredField === null
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  const handleOnChecked = (checked: boolean) => {
    setModelVm({ ...modelVm, specialEdition: checked });
  };

  const handleDescriptionGenerator = () => {
    setModelVm({
      ...modelVm,
      description: modelDescription(modelVm),
    });
  };

  const getSeriesNameById = (id: string) => {
    const item = seriesSettings.find(settings => settings.id === id);
    return !item ? '' : item.name;
  };

  const getSeriesNames = () => {
    return seriesSettings.map(settings => settings.name);
  };

  const defaultFuelType = possibleFuelTypes.length > 0 ? possibleFuelTypes[0] : '';
  const currentFuelType = possibleFuelTypes.includes(modelVm.fuelType?.value)
    ? modelVm.fuelType?.value
    : defaultFuelType;

  return (
    <TableRow zebra hoverShadow className={rowClass}>
      <td colSpan={6}>
        <section className={styles.addModel}>
          <div className={styles.row}>
            <div className={styles.short}>
              <Label htmlFor={`amm-name-${model.uid}`}>Model Name</Label>
              <Input
                id={`amm-name-${model.uid}`}
                defaultValue={
                  modelVm.name ? modelVm.name : setModelVm({ ...modelVm, name: seriesName })
                }
                onChange={e => setModelVm({ ...modelVm, name: e.currentTarget.value.trim() })}
                className={styles.bigerHeight}
              />
            </div>
            <div>
              <Label htmlFor={`amm-grade-${model.uid}`}>Grade</Label>
              <Input
                id={`amm-grade-${model.uid}`}
                defaultValue={modelVm.grade.id}
                onChange={e => {
                  const grade = new RefItem();
                  const gradeVal = e.currentTarget.value.trim();
                  grade.id = grade.value = gradeVal;
                  setModelVm({ ...modelVm, grade });
                }}
                className={styles.bigerHeight}
              />
            </div>
            <div>
              <Label htmlFor={`amm-packageTrim-${model.uid}`}>Package/Trim</Label>
              <Input
                id={`amm-packageTrim-${model.uid}`}
                defaultValue={modelVm.packageTrim}
                onChange={e =>
                  setModelVm({ ...modelVm, packageTrim: e.currentTarget.value.trim() })
                }
                className={styles.bigerHeight}
              />
            </div>
            <div className={styles.short}>
              <Label htmlFor={`amm-drive-${model.uid}`}>Drive</Label>
              <Input
                id={`amm-drive-${model.uid}`}
                defaultValue={modelVm.drive}
                onChange={e => setModelVm({ ...modelVm, drive: e.currentTarget.value.trim() })}
                className={styles.bigerHeight}
              />
            </div>
            <div className={styles.short}>
              <Label htmlFor={`amm-engine-${model.uid}`}>Engine</Label>
              <Input
                id={`amm-engine-${model.uid}`}
                defaultValue={modelVm.engine}
                onChange={e => setModelVm({ ...modelVm, engine: e.currentTarget.value.trim() })}
                className={styles.bigerHeight}
              />
            </div>
            <div className={styles.long}>
              <Label htmlFor={`amm-description-${model.uid}`}>Description</Label>
              <IconTextButton
                title="Populate description using Model Name, Grade, Package/Trim"
                icon="random"
                text=""
                className={styles.populate}
                onClick={handleDescriptionGenerator}
              >
                <FontAwesomeIcon icon={faCompressArrowsAlt} />
              </IconTextButton>
              <Input
                id={`amm-description-${model.uid}`}
                value={modelVm.description}
                onChange={e => setModelVm({ ...modelVm, description: e.currentTarget.value })}
                className={styles.bigerHeight}
              />
            </div>
            <div className={styles.long}>
              <Label htmlFor={`amm-alternativeOffersDescription-${model.uid}`}>
                Alternative (Offers) Description
              </Label>
              <Input
                id={`amm-alternativeOffersDescription-${model.uid}`}
                defaultValue={modelVm.alternativeOffersDescription}
                onChange={e =>
                  setModelVm({
                    ...modelVm,
                    alternativeOffersDescription: e.currentTarget.value.trim(),
                  })
                }
                className={styles.bigerHeight}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.short}>
              <Label htmlFor={`amm-code-${model.uid}`}>Model Code</Label>
              <Input
                id={`amm-code-${model.uid}`}
                defaultValue={modelVm.code}
                onChange={e => setModelVm({ ...modelVm, code: e.currentTarget.value.trim() })}
                className={styles.bigerHeight}
              />
            </div>
            <div>
              <Label htmlFor={`amm-series-${model.uid}`}>Series</Label>
              <DropdownList
                btnClass={styles.dropdown}
                value={getSeriesNameById(modelVm.seriesSettingId)}
                list={getSeriesNames()}
                onSelect={value => {
                  const settings = seriesSettings.find(settings => settings.name === value);
                  setModelVm({
                    ...modelVm,
                    seriesSettingId: settings ? settings.id : '',
                  });
                }}
              />
            </div>
            <div>
              <Label htmlFor={`amm-fueltype-${model.uid}`}>Fuel Type</Label>
              <DropdownList
                btnClass={styles.dropdown}
                value={currentFuelType}
                list={possibleFuelTypes}
                onSelect={value => {
                  setModelVm({
                    ...modelVm,
                    fuelType: fuelTypes.find(item => item.value === value),
                  });
                }}
              />
            </div>
            <div className={styles.short}>
              <Label htmlFor={`amm-cbuNap-${model.uid}`} optional>
                CBU/NAP
              </Label>
              <Input
                id={`amm-cbuNap-${model.uid}`}
                defaultValue={modelVm.cbuNap}
                onChange={e => setModelVm({ ...modelVm, cbuNap: e.currentTarget.value.trim() })}
                className={styles.bigerHeight}
              />
            </div>
            <div className={styles.short}>
              <Label htmlFor={`amm-cbuNap-${model.uid}`} optional>
                MSRP
              </Label>
              <Input
                id={`amm-msrp-${model.uid}`}
                defaultValue={modelVm.msrp}
                onChange={e => setModelVm({ ...modelVm, msrp: e.currentTarget.value.trim() })}
                className={styles.bigerHeight}
              />
            </div>
            <div className={styles.verySmall}>
              <Label htmlFor={`amm-horsepower-${model.uid}`}>Horsepower</Label>
              <Input
                id={`amm-horsepower-${model.uid}`}
                defaultValue={modelVm.horsepower}
                onChange={e => setModelVm({ ...modelVm, horsepower: e.currentTarget.value.trim() })}
                className={styles.bigerHeight}
              />
            </div>{' '}
            <div>
              <Label htmlFor={`amm-requiredPackageCode-${model.uid}`}>
                Required Package Code(s)
              </Label>
              <Input
                id={`amm-requiredPackageCode-${model.uid}`}
                defaultValue={modelVm.requiredPackageCode}
                onChange={e =>
                  setModelVm({ ...modelVm, requiredPackageCode: e.currentTarget.value.trim() })
                }
                className={styles.bigerHeight}
              />
            </div>
            <div>
              <Label htmlFor={`amm-katashiki-${model.uid}`}>Katashiki</Label>
              <Input
                id={`amm-katashiki-${model.uid}`}
                defaultValue={modelVm.katashiki}
                onChange={e => setModelVm({ ...modelVm, katashiki: e.currentTarget.value.trim() })}
                className={styles.bigerHeight}
              />
            </div>
            <Checkbox
              id={`amm-specialEdition-${model.uid}`}
              checked={modelVm.specialEdition}
              onChange={e => handleOnChecked(e.currentTarget.checked)}
              className={styles.checkbox}
            >
              Special Edition?
            </Checkbox>
            <HiddenField />
            {!readOnly && (
              <>
                <div className={styles.ctas}>
                  <Button variant="transparent" onClick={() => onClose()}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleOnSave();
                    }}
                  >
                    Save Model
                  </Button>
                </div>
              </>
            )}
          </div>
        </section>
      </td>
    </TableRow>
  );
};

const modelDescription = (modelVm: any) => {
  return `${modelVm.name || ''} ${modelVm.grade.id ? modelVm.grade.id : ''} ${
    modelVm.packageTrim || ''
  }`;
};

export default ModelsEditPanelLexus;
