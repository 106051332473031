import { useState } from 'react';
import { KeyValueType } from '../models/common.model';

const useLimitedDataTableState = () => {
  const [checkboxTables, setCheckboxTables] = useState<KeyValueType<boolean>[]>([]);
  const [totalChecked, setTotalChecked] = useState<number>(0);

  const onClickAll = (tableIds: number[]) => {
    tableIds.forEach(tableId => {
      if (!checkboxTables[tableId]) {
        checkboxTables[tableId] = {};
        setCheckboxTables(checkboxTables);
      }
    });

    return () => {
      let total = totalChecked;
      let tables = checkboxTables;

      tableIds.forEach(tableId => {
        if (Object.values(tables[tableId]).every(item => item)) {
          Object.keys(tables[tableId]).forEach(id => {
            tables[tableId][id] = false;
          });
          total = total - Object.keys(tables[tableId]).length;
        } else {
          Object.keys(tables[tableId]).forEach(id => {
            if (!tables[tableId][id]) {
              tables[tableId][id] = true;
              total++;
            }
          });
        }
      });

      setTotalChecked(total);
      setCheckboxTables(tables);
    };
  };

  const onClickRow = (tableId: number, id: string) => {
    if (checkboxTables[tableId]?.[id] === undefined) {
      checkboxTables[tableId][id] = false;
      setCheckboxTables(checkboxTables);
    }

    return () => {
      checkboxTables[tableId][id] = !checkboxTables[tableId][id];

      let total = totalChecked;

      if (checkboxTables[tableId][id]) {
        total++;
      } else {
        total--;
      }

      setTotalChecked(total);
      setCheckboxTables(checkboxTables);
    };
  };

  return { checkboxTables, onClickAll, onClickRow, totalChecked };
};

export default useLimitedDataTableState;
