import { computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { removeNulls } from '../utils';
import { KeyValueType } from './common.model';
import { ReviewChangeMap, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';
import { BRAND_LEXUS, BRAND_TOYOTA, Brand } from './user.model';

export class SeriesSettingsItem {
  uid = '';
  id = '';
  revId = '';
  @observable name = '';
  @observable fuelTypes = {} as TypesCatItems;
  @observable seriesCategories = {} as TypesCatItems;
  @observable seating = '';
  @observable estimatedMileage = '';
  @observable mpge = '';
  @observable isPublishable = true;
  @observable horsepower = '';
  @observable startingMSRP = '';
  @observable brand = '';
  @observable isDeleted = false;
  @observable subSeries = {} as SubSeriesItem;
  @observable isNewSubSeries = false;
  @observable parentId = '';
  @observable parentRevId = '';
  @observable sortOrder = 0;
  @observable isSubSeries = false;
  @observable isMpgeRequired = false;
  @observable isRangeRequired = false;
  @observable isFuelCell = false;
  @observable changedAttributes: string[] = [];
  @observable fromTMNA: boolean = false;
  @observable range = '';
  @observable fieldStatus = {} as FieldStatus;
  @observable convertible: boolean = false;

  constructor(brand: Brand, seriesSetting?: SeriesSettingsResponse) {
    this.uid = uuidv4();
    this.brand = brand;
    if (seriesSetting) {
      Object.assign(this, removeNulls(seriesSetting));
    }
  }

  @computed get isValid() {
    const isMpgeValid = this.isMpgeRequired ? this.mpge !== '' : true;
    const isRangeValid =
      this.isRangeRequired && this.brand === BRAND_TOYOTA ? this.range !== '' : true;
    const isEstimatedMileageValid =
      this.isRangeRequired || this.isFuelCell ? true : this.estimatedMileage !== '';
    const commonProps =
      this.name !== '' &&
      this.seating !== '' &&
      isEstimatedMileageValid &&
      isMpgeValid &&
      isRangeValid &&
      Object.keys(this.fuelTypes).length &&
      Object.keys(this.seriesCategories).length;

    if (this.brand === BRAND_LEXUS) {
      return (
        this.name !== '' &&
        Object.keys(this.fuelTypes).length &&
        Object.keys(this.seriesCategories).length
      );
    }

    return commonProps;
  }

  getPayloadToyota(): SeriesSettingsRequest {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      fuelTypes: this.fuelTypes,
      seriesCategories: this.seriesCategories,
      seating: this.seating,
      estimatedMileage: this.estimatedMileage ?? '',
      mpge: this.mpge ?? '',
      isPublishable: this.isPublishable,
      isDeleted: this.isDeleted,
      subSeries: this.subSeries,
      range: this.range ?? '',
    };
  }

  getSubSeriesPayloadToyota(): SeriesSettingsRequest {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      fuelTypes: this.fuelTypes,
      seriesCategories: this.seriesCategories,
      seating: this.seating,
      estimatedMileage: this.estimatedMileage ?? '',
      mpge: this.mpge ?? '',
      isPublishable: this.isPublishable,
      isDeleted: this.isDeleted,
      parentId: this.parentId,
      parentRevId: this.parentRevId,
      range: this.range ?? '',
    };
  }

  getPayloadLexus(): SeriesSettingsRequest {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      fuelTypes: this.fuelTypes,
      seriesCategories: this.seriesCategories,
      seating: this.seating,
      estimatedMileage: this.estimatedMileage,
      mpge: this.mpge ?? '',
      isPublishable: this.isPublishable,
      isDeleted: this.isDeleted,
      horsepower: this.horsepower,
      startingMSRP: this.startingMSRP,
      subSeries: this.subSeries,
      range: this.range ?? '',
      convertible: this.convertible,
    };
  }

  getSubSeriesPayloadLexus(): SeriesSettingsRequest {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      fuelTypes: this.fuelTypes,
      seriesCategories: this.seriesCategories,
      seating: this.seating,
      estimatedMileage: this.estimatedMileage,
      mpge: this.mpge ?? '',
      isPublishable: this.isPublishable,
      isDeleted: this.isDeleted,
      horsepower: this.horsepower,
      startingMSRP: this.startingMSRP,
      parentId: this.parentId,
      parentRevId: this.parentRevId,
      range: this.range ?? '',
      convertible: this.convertible,
    };
  }
}

export interface FieldStatus {
  id: string;
  revId: string;
  estimatedMileage: number;
  mpge: number;
  seating: number;
}

export type UpdateSeriesSettingsStatusRequest = FieldStatus;

export type UpdateSeriesSettingsStatusResponse = FieldStatus;

export interface SeriesSettingsResponse {
  id: string;
  revId: string;
  name: string;
  fuelTypes: TypesCatItems;
  seriesCategories: TypesCatItems;
  seating: string;
  estimatedMileage: string;
  mpge: string;
  isPublishable: boolean;
  isDeleted?: boolean;
  horsepower?: string;
  startingMSRP?: string;
  subSeries?: SubSeriesItem;
  parentId?: string;
  parentRevId?: string;
  range?: string;
  changedAttributes?: string[];
  fromTMNA?: boolean;
  fieldStatus?: FieldStatus;
  convertible?: boolean;
}

export interface SeriesSettingsRequest extends SeriesSettingsResponse {}

export interface SeriesCategoryItem {
  id: string;
  name: string;
  revId: string;
}

export interface TypesCatChecklist {
  id: string;
  name: string;
  selected: boolean;
}

export type TypesCatItems = {
  [id: string]: boolean;
};

export type SubSeriesItem = {
  [seriesId: string]: SeriesSettingsItem;
};

export interface SeriesSettingsLangMap {
  [lang: string]: SeriesSettingsItem;
}

export interface SeriesSettingsMap {
  series: {
    [seriesId: string]: SeriesSettingsLangMap;
  };
  order: string[];
}

export interface SubSeriesSettingsReviewResponse extends SeriesSettingsItem {
  changes: KeyValueType<ReviewChangeResponse>;
}

export interface SeriesSettingsReviewResponse extends SeriesSettingsResponse {
  subSeries?: { [seriesId: string]: SubSeriesSettingsReviewResponse };
  changes: KeyValueType<ReviewChangeResponse>;
}

export interface SeriesSettingsReviewMap {
  [id: string]: SeriesSettingsChangeTypeMap;
}

export interface SeriesSettingsChangeTypeMap extends ReviewChangeTypeMap {
  parentId?: string;
  isPublishable: boolean;
  name: ReviewChangeMap<string>;
  fuelTypes: ReviewChangeMap<KeyValueType<boolean>>;
  seriesCategories: ReviewChangeMap<KeyValueType<boolean>>;
  seating: ReviewChangeMap<string>;
  estimatedMileage: ReviewChangeMap<string>;
  mpge: ReviewChangeMap<string>;
  range: ReviewChangeMap<string>;
}

export type SeriesSettingsReviewType =
  | 'name'
  | 'fuelTypes'
  | 'seriesCategories'
  | 'seating'
  | 'estimatedMileage'
  | 'mpge'
  | 'range'
  | 'added'
  | 'deleted';
