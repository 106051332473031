import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import { RefItemLanguageMap } from '../models/category.model';
import { ChangeLogModelRequest, ChangeLogModelResponse } from '../models/changeLog.model';
import { KeyValueType } from '../models/common.model';
import { RefItemResponse } from '../models/refItem.model';
import { ReviewChangeRequest } from '../models/review.model';
import {
  SeriesSettingsItem,
  SeriesSettingsRequest,
  SeriesSettingsResponse,
  SeriesSettingsReviewResponse,
  UpdateSeriesSettingsStatusRequest,
  UpdateSeriesSettingsStatusResponse,
} from '../models/seriesSettings.model';
import { Language } from '../models/user.model';
import { SortModel, VehicleDataVersionInfo, VehicleTeam } from '../models/vehicleData.model';
import {
  ModelLangMap,
  ModelsReviewResponse,
  VehicleModel,
  VehicleModelLexus,
  VehicleModelToyota,
  UpdateModelStatusRequest,
  UpdateModelStatusReponse,
  UpdateAllModelStatusesRequest,
  UpdateAllModelStatusesResponse,
} from '../models/vehicleModel.model';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-models';
const INCLUDE_ALL = '&includeAll=y';
const MODELS_PATH = '/models';
const SERIES_SETTINGS_PATH = '/seriesSettings';
const MODEL_STATUS_PATH = '/models-status';
const SERIES_SETTINGS_STATUS_PATH = '/seriesSettings-status';

// Models
//
export const getModels = async ({
  brand,
  team,
  series,
  year,
  version,
  includeAll,
  language,
}: {
  brand: string;
  team: VehicleTeam;
  series: string;
  year: string;
  version?: string;
  includeAll?: boolean;
  language?: Language;
}) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += INCLUDE_ALL;
  }
  const lang = language ?? Language.EN;
  return API.get<VehicleModel<VehicleModelLexus | VehicleModelToyota>[]>(
    `${RESOURCE_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}/${lang}?${params}`
  );
};

export const addModel = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: VehicleModel<VehicleModelLexus | VehicleModelToyota>,
  language: Language = Language.EN
) => {
  return API.post<VehicleModel<VehicleModelLexus | VehicleModelToyota>>(
    `${RESOURCE_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}/${language}`,
    payload
  );
};

export const updateModel = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: VehicleModel<VehicleModelLexus | VehicleModelToyota>,
  language?: Language,
  unlinkFromTMNA: boolean = false
) => {
  const lang = language ?? Language.EN;
  if (unlinkFromTMNA) {
    payload.acceptChanges = true;
  }
  return API.put<VehicleModel<VehicleModelLexus | VehicleModelToyota>>(
    `${RESOURCE_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}/${lang}`,
    { ...payload, unlinkFromTMNA }
  );
};

export const deleteModel = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  code: string
) => {
  return API.delete(
    `${RESOURCE_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}/${lang}/${code}`
  );
};

export const sortModels = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: SortModel[]
) => {
  return API.post<SortModel[]>(
    `${RESOURCE_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}/sort`,
    { requests: payload }
  );
};

//Grades
//
const GRADES_PATH = '/grades';

export const getGrades = async ({
  brand,
  team,
  series,
  year,
  version,
  includeAll,
  language,
}: {
  brand: string;
  team: VehicleTeam;
  series: string;
  year: string;
  version?: string;
  includeAll?: boolean;
  language?: Language;
}) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += INCLUDE_ALL;
  }
  const lang = language ?? Language.EN;
  return API.get<RefItemResponse[]>(
    `${RESOURCE_PATH}${GRADES_PATH}/${brand}/${team}/${series}/${year}/${lang}?${params}`
  );
};

export const addGrades = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  payload: { [lang: string]: string }
) => {
  return API.post<RefItemLanguageMap>(
    `${RESOURCE_PATH}${GRADES_PATH}/${brand}/${team}/${series}/${year}`,
    { grades: payload }
  );
};

export const updateGrade = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  id: string,
  name: string
) => {
  return API.put(
    `${RESOURCE_PATH}${GRADES_PATH}/${brand}/${team}/${series}/${year}/${lang}/${id}`,
    {
      name,
    }
  );
};

//Changelog
//
const CHANGE_LOG_PATH = '/change-log';
export const getChangeLog = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<ChangeLogModelResponse[]>(
    `${RESOURCE_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}/${language}${CHANGE_LOG_PATH}?${params}`
  );
};

export const revertChangeLog = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: ChangeLogModelRequest
) => {
  return API.put<VehicleModel<VehicleModelLexus | VehicleModelToyota>>(
    `${RESOURCE_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}/${language}${CHANGE_LOG_PATH}/revert`,
    payload
  );
};

// Review
//
const REVIEW_PATH = '/review';
export const getReviewModels = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<VehicleModel<VehicleModelLexus | VehicleModelToyota>[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};
export const getReviewModels2 = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? 'status=DRAFT' : `version=${version}`;
  return API.get<ModelsReviewResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const updateReviewModels = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: ReviewChangeRequest
) => {
  const params = !version || version === 'DRAFT' ? 'status=DRAFT' : `version=${version}`;
  return API.put<ReviewChangeRequest>(
    `${RESOURCE_PATH}${REVIEW_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}?${params}`,
    payload
  );
};

export const getReviewGrades = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += INCLUDE_ALL;
  }
  return API.get<RefItemResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${GRADES_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

// Series Settings

export const getSeriesSettings = (
  brand: string,
  team: VehicleTeam,
  id: string,
  year: string,
  language: Language,
  version?: string,
  includeAll?: boolean
) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += INCLUDE_ALL;
  }
  return API.get<SeriesSettingsResponse[]>(
    `${RESOURCE_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${id}/${year}/${language}?${params}`
  );
};

export const addSeriesSetting = (
  brand: string,
  team: VehicleTeam,
  id: string,
  year: string,
  lang: string,
  payload: SeriesSettingsRequest
) => {
  return API.post<SeriesSettingsResponse>(
    `${RESOURCE_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${id}/${year}/${lang}`,
    payload
  );
};

export const updateSeriesSetting = (
  brand: string,
  team: VehicleTeam,
  id: string,
  year: string,
  lang: string,
  payload: SeriesSettingsRequest,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false
) => {
  const body: any = payload;
  if (acceptChanges) {
    body.acceptChanges = true;
  }
  if (unlinkFromTMNA) {
    body.unlinkFromTMNA = true;
    body.acceptChanges = true;
  }
  return API.put<SeriesSettingsResponse>(
    `${RESOURCE_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${id}/${year}/${lang}`,
    body
  );
};

export const deleteSeriesSetting = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  lang: string,
  seriesSetting: SeriesSettingsItem
) => {
  return API.delete(
    `${RESOURCE_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${seriesId}/${year}/${lang}/${seriesSetting.id}`
  );
};

export const deleteSubSeriesSetting = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  lang: string,
  seriesSetting: SeriesSettingsItem
) => {
  return API.delete(
    `${RESOURCE_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${seriesId}/${year}/${lang}/${seriesSetting.parentId}?subSeriesSettingId=${seriesSetting.id}`
  );
};

// Series Settings Review

/**
 * Retrieves the series settings data used in series settings review
 * @param brand
 * @param team
 * @param series
 * @param year
 * @param version
 * @returns
 */
export const getReviewSeriesSettings = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? 'status=DRAFT' : `version=${version}`;
  return API.get<SeriesSettingsReviewResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

// For BnP
export const getModelMaps = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  versionMap: VehicleDataVersionInfo
) => {
  return API.post<ModelLangMap>(
    `${RESOURCE_PATH}/model-maps/${brand}/${team}/${seriesId}/${year}`,
    {
      versionMap,
    }
  );
};

// Go live date
//

const GO_LIVE_DATE_PATH = 'goLiveDate';
export const updateGoLiveDate = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: Language,
  goLiveDate: string,
  payload: KeyValueType<string>
) => {
  const lang = language ?? Language.EN;
  return API.put<VehicleModel<VehicleModelLexus | VehicleModelToyota>>(
    `${RESOURCE_PATH}/${GO_LIVE_DATE_PATH}/${brand}/${team}/${series}/${year}/${lang}`,
    { goLiveDate, payload }
  );
};

export const updateModelStatuses = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: UpdateModelStatusRequest
) => {
  return API.put<UpdateModelStatusReponse>(
    `${RESOURCE_PATH}${MODEL_STATUS_PATH}/${brand}/${team}/${seriesId}/${modelYear}/${language}`,
    payload
  );
};

export const updateAllModelStatuses = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: UpdateAllModelStatusesRequest
) => {
  return API.post<UpdateAllModelStatusesResponse>(
    `${RESOURCE_PATH}${MODEL_STATUS_PATH}/${brand}/${team}/${seriesId}/${modelYear}/${language}/all`,
    payload
  );
};

export const updateSeriesSettingsStatuses = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: UpdateSeriesSettingsStatusRequest
) => {
  return API.put<UpdateSeriesSettingsStatusResponse>(
    `${RESOURCE_PATH}${SERIES_SETTINGS_STATUS_PATH}/${brand}/${team}/${seriesId}/${modelYear}/${language}`,
    payload
  );
};
